<template>
<b-card no-body class=" card-custom">
    <template #header>
        <div class="card-title m-0">
            <h3 class="card-label m-0">Attributes</h3>
        </div>
        <div class="card-toolbar">
            <router-link to="/attribute/form">
                <b-button v-b-modal.modal-1 variant="primary"><i class="las la-plus"></i> Add New</b-button>
            </router-link>
        </div>
    </template>

    <div class="card card-custom gutter-b card-stretch card-shadowless">
        <!-- User Interface controls -->

        <div class="card-body pt-0">
            <DataTable ref="dt" :value="items" dataKey="id" :paginator="true" :rows="10" :filters="filters" class="mt-0" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products">
                <template #header>
                    <b-form-group class="custom-search-table">
                        <b-row>

                            <b-col md="6" lg="3">
                                <label for="search">Search</label>
                                <b-input-group size="md" class="mb-2">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="search"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" placeholder="Search" class="rounded-sm" v-model="filters['global']">
                                    </b-form-input>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </template>

        <Column style="width: 3rem" field="sl"  header="#" :exportable="false" > <template #body="slotProps">
            {{ slotProps.index+1 }}
          </template></Column>
        <Column
          field="name"
          header="Attribute Name"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
        </Column>
        <Column
          field="group"
          header="Attribute Group"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.group_name }}
          </template>
        </Column>
         <Column
          field="order"
          header="Sort Order"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.sort_order }}
          </template>
        </Column>
        <Column
          field="action"
          header="Action"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
              <router-link class="btn btn-icon btn-light btn-hover-primary btn-sm" :to="`/attribute/form/${slotProps.data.id}`">
                <span class="svg-icon svg-icon-md svg-icon-primary"><svg version="1.1" viewBox="0 0 24 24" height="24px" width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                    <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Communication-/-Write" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                        <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" id="Path-11" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path>
                        <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    </g>
                </svg></span>
            </router-link>
             <a class="btn btn-icon btn-light btn-hover-primary btn-sm m-2">
                    <span @click="deleteAttribute(slotProps.data.id)" class="svg-icon svg-icon-md svg-icon-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                  </a>
          </template>
        </Column>
      </DataTable>
        </div>
    </div>
</b-card>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Swal from "sweetalert2";
//api services
import AttributeService from "@/core/services/api/attribute";
export default {
    components: {
        DataTable,
        Column,

    },
    data() {
        return {
            filters: {},
            items: [],
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
                title: "Dashboard",
                route: "/dashboard",
            },
            {
                title: "Attribute",
            },
        ]);
         this.getAttributes();
    },
    methods: {
        async getAttributes() {
            var response = await AttributeService.getAttributes();
            this.items = response.data;
        },
        deleteAttribute(id){
            var vm = this;
            Swal.fire({
                icon: 'warning',
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                     AttributeService.deleteAttribute(id)
                        .then(function (response) {
                                vm.getAttributes();
                                let timerInterval;
                                Swal.fire({
                                title: response.message,
                                timer: 1000,
                                icon: "success",
                                timerProgressBar: true,
                                showConfirmButton: false,
                                showCancelButton: false,
                                didOpen: () => {
                                    Swal.showLoading();
                                    const b = Swal.getHtmlContainer().querySelector("b");
                                    timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft();
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                                }).then((result) => {
                                  /* Read more about handling dismissals below */
                                  if (result.dismiss === Swal.DismissReason.timer) {
                                  }
                                });
                        })
                        .catch(function () {
                        });
                }
            });
            
        }
    },
};
</script>

<style></style>
